var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageLoading)?_c('v-container',{staticClass:"bg-color-theme px-3"},[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line, card"}})],1):_c('v-container',{staticClass:"bg-color-theme px-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"store-detail-main font-Roboto"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.updateShop.apply(null, arguments)}}},[(_vm.getShop.id)?_c('div',{staticClass:"detail-top d-flex justify-space-between align-center"},[(_vm.show)?_c('div',{staticClass:"top-left d-flex mt-2"},[_c('v-switch',{staticClass:"mt-0 pt-0 switch-status",class:("switch-status " + (_vm.switch1 ? 'green-text' : 'red-text') + " "),attrs:{"hide-details":""},model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}}),(_vm.switch1)?_c('p',{staticClass:"gmb-0 font-15px green-text"},[_vm._v(" データ管理中 ")]):_c('p',{staticClass:"red-text gmb-0 font-15px"},[_vm._v(" データ管理しない ")])],1):_vm._e(),(_vm.show)?_c('div',{staticClass:"top-rgt d-flex align-center mb-auto mt-1"},[_c('span',{staticClass:"mr-2 font-15px font-weight-500 sptext"},[_vm._v("一覧表示順")]),_c('validation-provider',{attrs:{"name":"display_order","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"font-15px font-weight-500 rightselect",attrs:{"dense":"","solo":"","hide-details":"","items":[1, 2, 3, 4, 5, 6, 7, 8, 9, 10],"label":_vm.order && _vm.order != '0' ? _vm.order : 1,"outlined":"","error-messages":errors},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}})]}}],null,false,2222316420)})],1):_vm._e()]):_vm._e(),_c('p',{staticClass:"mt-4 font-weight-bold"},[_vm._v("店舗情報")]),_c('v-card',{staticClass:"form-main-card px-4 py-4 mt-5"},[_vm._l((_vm.fields),function(field){return _c('v-card-text',{key:field.name,staticClass:"pb-0"},[(field.type == 'text')?_c('v-row',{staticClass:"py-1"},[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-15px",attrs:{"label":field.label,"error-messages":errors,"disabled":_vm.disabled},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)],1):_vm._e(),(field.type == 'no-field')?_c('v-row',{staticClass:"py-1"},[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('label',{staticClass:"form-label font-13px text--secondary"},[_vm._v(_vm._s(field.label))]),_c('p',{staticClass:"font-15px font-weight-medium text--primary"},[_vm._v(" "+_vm._s(field.value)+" ")])])],1):_vm._e(),(field.type == 'datepicker')?_c('v-row',{staticClass:"py-1"},[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_c('div',{staticClass:"form-label font-13px text--secondary"},[_vm._v(" "+_vm._s(field.label)+" ")])]),_c('DatePicker',{attrs:{"separator":'-',"field":field,"errors":errors,"disabled":_vm.disabled,"dense":""},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)],1):_vm._e(),(field.type == 'dropdown')?_c('v-row',{staticClass:"py-1"},[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":field.cols ? field.cols : 12,"md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label font-13px text--secondary"},[_vm._v(_vm._s(field.label))]),_c('v-select',{staticClass:"font-15px font-weight-500 mr-2",attrs:{"dense":"","placeholder":field.placeholder,"items":field.items,"item-text":field.item_text,"item-value":field.item_value,"error-messages":errors,"disabled":_vm.getShopAuthUser[0].user_role != 'M'
                            ? true
                            : field.disabled},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)],1):_vm._e()],1)}),(_vm.show)?_c('v-btn',{staticClass:"stepper-btns mt-3",attrs:{"block":"","color":"primary","type":"submit","loading":_vm.loading}},[_vm._v(" 保存する ")]):_vm._e(),(_vm.show)?_c('p',{staticClass:"delete-btn mt-3 font-15px font-weight-medium",attrs:{"role":"button"},on:{"click":_vm.deleteItem}},[_vm._v(" 削除する ")]):_vm._e()],2)],1)],1)],1)])],1),(_vm.isAddUser)?_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("共有用アカウント")]),_c('v-list',{staticClass:"details-list",attrs:{"dense":"","color":"transparent"}},[_c('v-list-item-group',[(_vm.getShop.shared_account)?_c('v-list-item',{staticClass:"item-list item-active",attrs:{"to":{
              name: 'SharedAccountIndex',
              params: { id: _vm.getShop.shared_account.id },
              query: { shop: _vm.id }
            }}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-15px primary--text font-weight-regular"},[_vm._v("登録スタッフ："+_vm._s(_vm.getShop.shared_account.shared_accounts.length)+"名")])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"size":"15","color":"white"}},[_vm._v("$rightArrow")])],1)],1):_c('v-list-item',{staticClass:"item-list item-active",attrs:{"to":{ name: 'SharedAccountRegistration', params: { shop: _vm.id } }}},[(_vm.isAddUser)?_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-15px primary--text font-weight-regular"},[_vm._v("共有用アカウントを作成する")])],1):_vm._e(),_c('v-list-item-action',[_c('v-icon',{attrs:{"size":"15","color":"white"}},[_vm._v("$rightArrow")])],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-4 mb-5"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('p',{staticClass:"font-weight-bold mb-2"},[_vm._v("ユーザー一覧")]),_c('div',{staticClass:"text-right"},[(_vm.isAddUser)?_c('v-btn',{staticClass:"primary--text mb-1",attrs:{"text":"","to":{ name: 'addUser', params: { shop: _vm.id } },"right":""}},[_vm._v("＋ 追加する")]):_vm._e()],1),_c('v-list',{staticClass:"details-list",attrs:{"dense":"","color":"transparent"}},[_c('v-list-item-group',_vm._l((_vm.getShop.user_shop),function(user,index){return _c('span',{key:index},[(user.user_role != 'S')?_c('v-list-item',{staticClass:"item-list item-active",attrs:{"to":_vm.getShopAuthUser[0].user_role != 'M'
                  ? ''
                  : {
                      name: 'userDetails',
                      params: { id: user.user_id },
                      query: { shop: _vm.id }
                    }}},[_c('v-list-item-icon',{staticClass:"my-auto mr-2 d-flex align-center"},[(user.user && user.user.avatar != '')?[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"height":"40","width":"40","src":user.user.avatar}})],1)]:[_c('AvatarIcon')]],2),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-15px"},[_c('div',[_c('span',[_vm._v(" "+_vm._s(user.user ? user.user.surname + " " + user.user.name : "")+" ")]),_c('span',{staticClass:"user-type"},[_vm._v(_vm._s(user.user_role))])]),_c('p',{staticClass:"font-12px mb-0 font-Roboto"},[_vm._v(" "+_vm._s(user.user ? user.user.position : "")+" ")])])],1),(_vm.show)?_c('v-list-item-action',[_c('v-icon',{attrs:{"size":"15","color":"white"}},[_vm._v("$rightArrow")])],1):_vm._e()],1):_vm._e()],1)}),0)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }