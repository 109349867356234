<template>
  <v-container class="bg-color-theme">
    <v-row>
      <v-col cols="12" md="12">
        <div class="stores-list-main font-Roboto">
          <div class="d-flex justify-space-between align-center mb-6">
            <h2 class="font-18px font-weight-bold mb-0">管理店舗一覧</h2>
            <v-btn
              text
              :to="{ name: 'ShopRegForm' }"
              class="font-weight-400 primary--text font-14px px-0"
            >
              ＋ 新規店舗作成
            </v-btn>
          </div>
          <v-skeleton-loader
            v-if="loading"
            type="list-item-three-line"
          ></v-skeleton-loader>
          <v-list v-else dense color="transparent" class="details-list mb-10">
            <v-list-item-group>
              <v-list-item
                v-for="store in getShopList"
                :key="store.id"
                class="item-list item-active"
                :disabled="!store || store.status == '2'"
                :to="{ name: 'StoreDetails', params: { id: store.id } }"
                :class="
                  store.status == '0'
                    ? 'item-disabled'
                    : store.status == '2'
                    ? 'item-disabled connecting'
                    : ''
                "
              >
                <v-list-item-icon class="my-auto mr-2 d-flex align-center">
                  <v-icon class="circle-checked" size="15" color="#000"
                    >$circleChecked</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content class="py-2">
                  <v-list-item-title class="font-15px font-weight-regular">
                  {{ store.store_display_name ? store.store_display_name : store.store_name}}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon size="15" color="white">$rightArrow</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
            <div class="text-center" v-if="syncing == '1'">
              <v-progress-circular
                indeterminate
                color="indigo darken-2"
              ></v-progress-circular>
            </div>
          </v-list>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ListStores",
  data() {
    return {
      loading: false,
      syncing: "0"
    };
  },
  computed: {
    ...mapGetters(["getAllShops","user"]),
    getShopList(){
      let shopList = [];
      this.getAllShops.map(shop => {
        shop?.user_shop.map(userShop => {
          if(this.user.id === userShop.user_id){
            shopList.push({
              id:shop.id,
              store_display_name:shop.store_display_name,
              store_name:shop.store_name,
              status:userShop.shop_status,
              order:userShop.shop_display_order,
            });
          }
        });
      });
      shopList.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1));
      return shopList
    }
  }
};
</script>

<style lang="scss" scope src="./style.scss"></style>
