<template>
  <v-container v-if="pageLoading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>
  <v-container v-else class="bg-color-theme px-3">
    <v-row>
      <v-col cols="12" md="12">
        <div class="store-detail-main font-Roboto">
          <validation-observer ref="observer">
            <v-form
              ref="form"
              @submit.prevent="updateShop"
              lazy-validation
              autocomplete="off"
            >
              <div
                v-if="getShop.id"
                class="detail-top d-flex justify-space-between align-center"
              >
                <div class="top-left d-flex mt-2" v-if="show">
                  <v-switch
                    class="mt-0 pt-0 switch-status"
                    :class="
                      `switch-status ${switch1 ? 'green-text' : 'red-text'} `
                    "
                    v-model="switch1"
                    hide-details
                  ></v-switch>
                  <p v-if="switch1" class="gmb-0 font-15px green-text">
                    データ管理中
                  </p>
                  <p v-else class="red-text gmb-0 font-15px">
                    データ管理しない
                  </p>
                </div>
                <div
                  class="top-rgt d-flex align-center mb-auto mt-1"
                  v-if="show"
                >
                  <span class="mr-2 font-15px font-weight-500 sptext"
                    >一覧表示順</span
                  >
                  <validation-provider
                    v-slot="{ errors }"
                    name="display_order"
                    rules=""
                  >
                    <v-select
                      class="font-15px font-weight-500 rightselect"
                      dense
                      solo
                      hide-details
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      :label="order && order != '0' ? order : 1"
                      outlined
                      :error-messages="errors"
                      v-model="order"
                    ></v-select>
                  </validation-provider>
                </div>
              </div>
              <p class="mt-4 font-weight-bold">店舗情報</p>

              <v-card class="form-main-card px-4 py-4 mt-5">
                <v-card-text
                  class="pb-0"
                  v-for="field in fields"
                  :key="field.name"
                >
                  <v-row v-if="field.type == 'text'" class="py-1">
                    <v-col
                      cols="12"
                      :md="field.additional_field ? '6' : '12'"
                      class="py-0 px-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="field.rules"
                        :name="field.name"
                      >
                        <v-text-field
                          :label="field.label"
                          :error-messages="errors"
                          v-model="field.value"
                          class="font-15px"
                          :disabled="disabled"
                        >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row v-if="field.type == 'no-field'" class="py-1">
                    <v-col
                      cols="12"
                      :md="field.additional_field ? '6' : '12'"
                      class="py-0 px-0"
                    >
                      <label class="form-label font-13px text--secondary">{{
                        field.label
                      }}</label>
                      <p class="font-15px font-weight-medium text--primary">
                        {{ field.value }}
                      </p>
                    </v-col>
                  </v-row>

                  <v-row v-if="field.type == 'datepicker'" class="py-1">
                    <v-col
                      cols="12"
                      :md="field.additional_field ? '6' : '12'"
                      class="py-0 px-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="field.rules"
                        :name="field.name"
                      >
                        <v-label>
                          <div class="form-label font-13px text--secondary">
                            {{ field.label }}
                          </div>

                          <!--<span v-if="field.rules.includes('required')" class="required-text">必須</span>-->
                        </v-label>
                        <DatePicker
                          v-model="field.value"
                          :separator="'-'"
                          :field="field"
                          :errors="errors"
                          :disabled="disabled"
                          dense
                        ></DatePicker>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row v-if="field.type == 'dropdown'" class="py-1">
                    <v-col
                      :cols="field.cols ? field.cols : 12"
                      :md="field.additional_field ? '6' : '12'"
                      class="py-0 px-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="field.rules"
                        :name="field.name"
                      >
                        <label class="form-label font-13px text--secondary">{{
                          field.label
                        }}</label>

                        <v-select
                          class="font-15px font-weight-500 mr-2"
                          dense
                          :placeholder="field.placeholder"
                          :items="field.items"
                          :item-text="field.item_text"
                          :item-value="field.item_value"
                          :error-messages="errors"
                          v-model="field.value"
                          :disabled="
                            getShopAuthUser[0].user_role != 'M'
                              ? true
                              : field.disabled
                          "
                        ></v-select>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-btn
                  class="stepper-btns mt-3"
                  block
                  color="primary"
                  type="submit"
                  :loading="loading"
                  v-if="show"
                >
                  保存する
                </v-btn>
                <p
                  role="button"
                  class="delete-btn mt-3 font-15px font-weight-medium"
                  @click="deleteItem"
                  v-if="show"
                >
                  削除する
                </p>
              </v-card>
            </v-form>
          </validation-observer>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-6" v-if="isAddUser">
      <v-col cols="12" md="12">
        <p class="font-weight-bold">共有用アカウント</p>
        <v-list dense color="transparent" class="details-list">
          <v-list-item-group>
            <v-list-item
              v-if="getShop.shared_account"
              class="item-list item-active"
              :to="{
                name: 'SharedAccountIndex',
                params: { id: getShop.shared_account.id },
                query: { shop: id }
              }"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="font-15px primary--text font-weight-regular"
                  >登録スタッフ：{{
                    getShop.shared_account.shared_accounts.length
                  }}名</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon size="15" color="white">$rightArrow</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-else
              class="item-list item-active"
              :to="{ name: 'SharedAccountRegistration', params: { shop: id } }"
            >
              <v-list-item-content v-if="isAddUser">
                <v-list-item-title
                  class="font-15px primary--text font-weight-regular"
                  >共有用アカウントを作成する</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon size="15" color="white">$rightArrow</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>

    <v-row class="mt-4 mb-5">
      <v-col cols="12" md="12">
        <p class="font-weight-bold mb-2">ユーザー一覧</p>
        <div class="text-right">
          <v-btn
            text
            :to="{ name: 'addUser', params: { shop: id } }"
            right
            class="primary--text mb-1"
            v-if="isAddUser"
            >＋ 追加する</v-btn
          >
        </div>
        <v-list dense color="transparent" class="details-list">
          <v-list-item-group>
            <span v-for="(user, index) in getShop.user_shop" :key="index">
              <v-list-item
                :to="
                  getShopAuthUser[0].user_role != 'M'
                    ? ''
                    : {
                        name: 'userDetails',
                        params: { id: user.user_id },
                        query: { shop: id }
                      }
                "
                class="item-list item-active"
                v-if="user.user_role != 'S'"
              >
                <v-list-item-icon class="my-auto mr-2 d-flex align-center">
                  <template v-if="user.user && user.user.avatar != ''">
                    <v-avatar size="40">
                      <v-img height="40" width="40" :src="user.user.avatar">
                      </v-img>
                    </v-avatar>
                  </template>
                  <template v-else>
                    <AvatarIcon />
                  </template>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-15px">
                    <div>
                      <span>
                        {{
                          user.user
                            ? user.user.surname + " " + user.user.name
                            : ""
                        }}
                      </span>
                      <span class="user-type">{{ user.user_role }}</span>
                    </div>
                    <p class="font-12px mb-0 font-Roboto">
                      {{ user.user ? user.user.position : "" }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="show">
                  <v-icon size="15" color="white">$rightArrow</v-icon>
                </v-list-item-action>
              </v-list-item>
            </span>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "@/components/ui/DatePicker";
import AvatarIcon from "@/components/icons/AvatarIcon";
import { mapGetters } from "vuex";

export default {
  name: "StoreDetails",
  components: { AvatarIcon, DatePicker },
  props: ["id"],
  data() {
    return {
      disabled: false,
      show: true,
      isAddUser: true,
      loading: false,
      pageLoading: false,
      months: [1, 2, 3, 4],
      days: [],
      selectedMonth: null,
      selectedDay: null,
      errors: "",
      newConnect: true,
      modalTitle: "",
      modalBody: "",
      fields: [
        {
          label: "表示名",
          name: "store_display_name",
          type: "text",
          placeholder: "",
          value: "",
          rules: ""
        },
        {
          label: "登録名",
          name: "store_name",
          type: "text",
          placeholder: "",
          value: "",
          rules: "required"
        },
        {
          label: "電話番号",
          name: "phone_number",
          type: "text",
          placeholder: "",
          value: "",
          rules: "required|enter_half_width_numbers_hyphens"
        },
        {
          label: "郵便番号",
          name: "postal_code",
          type: "text",
          placeholder: "",
          value: "",
          rules: "required"
        },
        {
          label: "都道府県",
          name: "prefectures",
          type: "dropdown",
          items: this.getPrefectureData(),
          item_value: "id",
          item_text: "value",
          selection_id: true,
          placeholder: "東京都",
          value: "",
          rules: ""
        },
        {
          label: "市区町村・番地",
          name: "city_address",
          type: "text",
          placeholder: "",
          value: "",
          rules: ""
        },
        {
          label: "建物名・部屋番号等",
          name: "building_name_room_number",
          type: "text",
          placeholder: "",
          value: "",
          rules: ""
        },
        {
          label: "メールアドレス",
          name: "mail_address",
          type: "text",
          placeholder: "",
          value: "",
          rules: "email"
        },
        {
          label: "代表者",
          name: "representative",
          type: "text",
          placeholder: "",
          value: "",
          rules: ""
        },
        {
          label: "会社形態",
          name: "company_type",
          type: "dropdown",
          items: this.$store.getters.getMasterData?.company_type,
          item_value: "id",
          item_text: "value",
          selection_id: true,
          placeholder: "個人事業主",
          value: "",
          rules: ""
        },
        {
          label: "従業員数",
          name: "number_of_employees",
          type: "dropdown",
          items: this.$store.getters.getMasterData?.number_of_employees,
          item_value: "id",
          item_text: "value",
          selection_id: true,
          placeholder: "2〜5名",
          value: "",
          rules: "numeric"
        },
        {
          label: "登記日",
          name: "registration_date",
          type: "datepicker",
          placeholder: "2021/01",
          value: "",
          rules: "required:登記日",
          locale: "ja",
          pickerType: "month",
          date_format: "YYYY-MM",
          date: "",
          menu: false
        },
        {
          label: "事業年度の開始月",
          name: "start_of_fiscal_year",
          type: "dropdown",
          placeholder: "1月",
          rules: "required",
          items: [
            { id: 1, value: "1月" },
            { id: 2, value: "2月" },
            { id: 3, value: "3月" },
            { id: 4, value: "4月" },
            { id: 5, value: "5月" },
            { id: 6, value: "6月" },
            { id: 7, value: "7月" },
            { id: 8, value: "8月" },
            { id: 9, value: "9月" },
            { id: 10, value: "10月" },
            { id: 11, value: "11月" },
            { id: 12, value: "12月" }
          ],
          item_value: "id",
          item_text: "value",
          value: "",
          disabled: true
        }
      ],
      switch1: true,
      order: 1
    };
  },
  created() {
    this.getDataFromApi();
    this.getPrefectureData();
  },
  mounted() {
    this.$route.meta.title =
      this.$store.getters.getShopAuthUser[0].user_role == "S"
        ? "店舗情報"
        : "管理店舗一覧";

    if (this.alert)
      this.$store.dispatch("SHOW_ALERT", {
        text: "ユーザーを追加しました。追加メンバーにメールを送信しました。",
        successStatus: "info"
      });
  },
  computed: {
    ...mapGetters(["getShop", "getShopAuthUser", "user"])
  },
  methods: {
    getPrefectureData() {
      return this.$store.getters.getPrefectures.map((prefecture, key) => {
        return { id: prefecture.id, value: prefecture.name };
      });
    },
    deleteItem() {
      this.$root.confirm
        .open(`本当に店舗を削除しますか?\nこの操作は取消できません。`)
        .then(res => {
          if (res) {
            let params = {};
            params.id = this.id;
            this.$store.dispatch("SHOP_DELETE", params).then(response => {
              if (response.status === 200) {
                this.$store.dispatch("SHOP_GET_ALL");
                this.$router.push({ path: "/shop" });
                this.$store.dispatch("SHOW_ALERT", {
                  text: "メールを削除しました。",
                  successStatus: "info"
                });
              }
            });
          }
        });
    },
    async getDataFromApi() {
      this.loading = true;
      this.pageLoading = true;
      await this.$store
        .dispatch("SHOP_GET", { id: this.$route.params.id })
        .then(response => {
          this.$store.dispatch("PREFECTURES_GET_ALL");
          let shop = response.data.data.shop;
          this.newConnect = shop.status == "1" ? false : true;

          let authUserData = {
            shop_id: this.$route.params.id, //Shop Id
            user_id: this.$store.getters.user.id
          };

          this.$store.dispatch("SHOP_USER_AUTH", authUserData).finally(() => {
            if (this.$store.getters.getShopAuthUser[0].user_role != "M") {
              if (this.getShopAuthUser[0].user_role != "M") {
                this.disabled = true;
                this.show = false;
              }

              if (this.getShopAuthUser[0].user_role == "S") {
                this.isAddUser = false;
              }
            }
            this.loading = false;
          });
        })
        .finally(() => {
          this.loading = false;
          this.pageLoading = false;
          this.assignShopData();
        });
    },
    assignShopData() {
      this.fields.forEach(field => {
        if (field?.selection_id) {
          field.value = parseInt(this.getShop?.[field.name]);
        } else {
          field.value = this.getShop?.[field.name];
        }
      });

      this.getShop?.user_shop.map(userShop => {
        if (this.user.id === userShop.user_id) {
          this.switch1 = userShop.shop_status == 1 ? true : false;
          this.order = userShop.shop_display_order;
        }
      });
    },
    updateShop() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }

        this.loading = true;
        this.pageLoading = true;
        let data = {};
        data.shop_id = this.id;
        this.fields.forEach(field => {
          if (field) {
            if (field.value) {
              data[field.name] = field.value;
            }
          }
        });

        data["shop_status"] = this.switch1 ? 1 : 0;
        data["shop_display_order"] = this.order;
        data["user_id"] = this.getShopAuthUser[0].user_id;

        this.$store
          .dispatch("SHOP_UPDATE", data)
          .then(response => {
            if (response.status === 200) {
              this.$store.dispatch("SHOP_GET_ALL");
              this.loading = false;
              this.pageLoading = false;
            }
          })
          .catch(error => {
            if (error?.data?.errors)
              this.$refs.observer.setErrors(error?.data?.errors);
          })
          .finally(() => (this.loading = false));
      });
    }
  }
};
</script>

<style lang="scss" scope src="./style.scss"></style>
